import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  Menu,
  MenuItem,
  FormControl,
  Select,
  Modal,
  InputLabel,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EyeMenu from "../../assets/eye-menu.svg";
import Trash from "../../assets/trahs.svg";
import CriticalImg from "../../assets/critical-image.svg";
import LowImage from '../../assets/low-img.svg';
import MediumImage from '../../assets/medium-img.svg';
import HighImage from '../../assets/high-img.svg';
import CrossImg from "../../assets/cross-select.svg";
import { CustomNoRowsOverlay } from "../Assessment.js";
import CalendarTodayIcon from '../../assets/calender-icon-select.svg';
import moment from "moment/moment";
import GetRequest from "../../components/apiConnections/getRequest.js";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import PostRequest from "../../components/apiConnections/postRequest.js";
import IssueSideBar from "./IssueSideBar.js";


const style = {
  position: "absolute",
  background: '#120A3A',
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  boxShadow: 24,
  maxHeight: "586px", // Set maximum height for scrolling
  overflowY: "auto",  // Enable vertical scrolling
};

function Issues({
  issuesListData
}) {
  const data = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [openCreateRemediation, setOpenCreateRemediation] = React.useState(false);
  const handleOpenCreateRemediation = () => setOpenCreateRemediation(true);
  const handleCloseCreateRemediation = () => setOpenCreateRemediation(false);
  const [openSidebar, setOpenSidebar] = React.useState(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const handlePaginationModelChange = (newPaginationModel) => {
    setPaginationModel(newPaginationModel);
  };
  const [selectedIssue, setSelectedIssue] = useState({})
  const [contactPersonList, setContactPersonList] = useState([]);
  const [vendorUrl, setVendorUrl] = useState(data.url);
  const [selectedContactPerson, setSelectedContactPerson] = useState("");
  const [loading, setLoading] = useState(false);

  const columnsNew = [
    {
      field: "id",
      headerName: "#",
      flex: 0.5,
      className: "serial-number",
      disableColumnMenu: true,
    },
    {
      field: "name",
      headerName: "Issue Name",
      flex: 1.1,
      minWidth: 170, // Ensure minimum width
      disableColumnMenu: true,
    },
    {
      field: "category",
      headerName: "Category",
      flex: 1,
      minWidth: 125,
      disableColumnMenu: true,
    },
    {
      field: "severity",
      headerName: "Severity",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        let scoreTag = "-";
        const severity = params.row.severity
        if (severity === 'low')
          scoreTag = "Low";
        if (severity === 'medium')
          scoreTag = "Medium";
        if (severity === 'critical')
          scoreTag = "Critical";
        else if (severity === 'high')
          scoreTag = "High";

        return (
          scoreTag ? (
            <span className={`tagClassapproval blank-white ${scoreTag}`}>
              <span>{scoreTag}</span>
            </span>
          ) : (
            "-"
          )
        );
      },
    },
    {
      field: "industry",
      headerName: "Found",
      flex: 1,
      minWidth: 100,
      disableColumnMenu: true,
      renderCell: () => {
        return <>23/05/2024</>;
      },
    },
    {
      field: "createRemedation",
      headerName: "",
      flex: 1.1,
      minWidth: 100,
      // disableColumnMenu: true,
      renderCell: (params) => {
        console.log(params.row,"dddddddddddddd")
        return (
          <span onClick={(e) => {
            e.stopPropagation();
            handleOpenCreateRemediation();
            setSelectedIssue(params?.row)
          }}
            className="create-remediation-button">
            {" "}
            + Create Remediation
          </span>
        );
      },
    },
  ];
  const [formValues, setFormValues] = useState({
    name: selectedIssue?.name || "",
    description: selectedIssue?.ele?.Description || "",
    url: selectedIssue?.ele?.URL || "",
    remediation: selectedIssue?.ele?.Remediation || "",
    deadline: "", // You can initialize the deadline to an empty string or any default value
    remark: ""
  });
  useEffect(() => {
    getVendorContactPerson();
  }, []);

  const filteredRows =
    (!issuesListData || !issuesListData.length) ? [] :
      issuesListData
        ?.map((val, i) => ({
          ...val,
          id: i + 1,
        }));

  const getVendorContactPerson = async () => {
    try {
      GetRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_DOMAIN_CONTACT_PERSON}?domain=${vendorUrl}`
      )
        .then((res) => {
          const obj2 = res.data.data;
          setContactPersonList(obj2);
        })
        .catch((err) => {
          // no data
          enqueueSnackbar("Failed Questions not find", { variant: "error" });
          return false;
        });
    } catch (error) {
      // no data
      enqueueSnackbar("Failed Questions not find", { variant: "error" });
      return false;
    }
  };
  // Menu component for the three-dot menu
  function RowMenu({ onViewClick, onDeleteClick }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <>
        <IconButton onClick={handleClick} className="three-dots-btn">
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className="rowmenu"
        >
          <MenuItem onClick={onViewClick} className="border-bot-line">
            <img src={EyeMenu} alt="icon" /> View
          </MenuItem>
          <MenuItem onClick={onDeleteClick}>
            <img src={Trash} alt="icon" /> Remove
          </MenuItem>
        </Menu>
      </>
    );
  }
  const handleRowClick = (params) => {
    console.log(params?.row,"qqqqqqqqqqqqqqqqqqqq")
    setSelectedIssue(params?.row)
    setOpenSidebar(true)
  };

  const getCurrentDate = (date) => {
    let parseDate = moment(date).add(1, 'day');
    let currentDate = new Date(parseDate);
    let year = currentDate.getFullYear();
    let month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    let day = currentDate.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (selectedIssue) {
      setFormValues({
        name: selectedIssue?.name || "",
        description: selectedIssue?.ele?.Description || "",
        url: selectedIssue?.ele?.URL?.join('\r\n') || "",
        remediation: selectedIssue?.ele?.Remediation || "",
        deadline: "",  // You can modify this based on your data
        remark: ""
      });
    }
  }, [selectedIssue]);

  // Handle input changes for each field
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const validateForm = (formValues, contactPerson) => {
    if (!formValues.deadline || formValues.deadline.trim() === "") {
      enqueueSnackbar("Please select deadline.", { variant: "error" });
      return false;
    }
    if (!contactPerson?.email || contactPerson?.email.trim() === "") {
      enqueueSnackbar("Please select a valid contact person.", { variant: "error" });
      return false;
    }
    if (!formValues.description || formValues.description.trim() === "") {
      enqueueSnackbar("Please provide a valid description.", { variant: "error" });
      return false;
    }
    if (!formValues.name || formValues.name.trim() === "") {
      enqueueSnackbar("Please provide a valid issue name.", { variant: "error" });
      return false;
    }
    if (!formValues.remark || formValues.remark.trim() === "") {
      enqueueSnackbar("Please provide a valid remark.", { variant: "error" });
      return false;
    }
    if (!formValues.url || Array.isArray(formValues.url) || formValues.url.length === 0) {
      enqueueSnackbar("Please provide a valid URL list.", { variant: "error" });
      return false;
    }
    return true;
  };

  const uniqueIdGenerator = (contactPerson) => {

    // let firstName = ((contactPerson?.firstName)?.slice(0, 3))?.toUpperCase();
    // let createdBy = (localStorage.getItem("userEmail"))?.slice(0, 3)?.toUpperCase();
    // let category = (selectedIssue.category)?.slice(0, 3)?.toUpperCase();
    // let issueId=selectedIssue?.id

    // let timeStamp = new Date().getTime();
    // let uniqueId = `GEN-${createdBy}-${firstName}-${category}-I${issueId}-${timeStamp}`;


    function generateSixDigitAlphanumeric() {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789#';
      let result = '';
      for (let i = 0; i < 6; i++) {
        result += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return result;
    }

    let alphaNumericId = generateSixDigitAlphanumeric();
    let uniqueId = `REM-${alphaNumericId}`;
    return uniqueId;
  }

  const sendRemediation = (event) => {

    event.preventDefault();
    // console.log('selectedIssue',selectedIssue)
    // return;
    const contactPerson = JSON.parse(selectedContactPerson || "{}");
    if (!validateForm(formValues, contactPerson)) {
      return;  // Stop execution if validation fails
    }
    setLoading(true);
    const payload = {
      uniqueId: uniqueIdGenerator(contactPerson),
      firstName: contactPerson?.firstName,
      lastName: contactPerson?.lastName,
      email: contactPerson?.email,
      deadline: formValues.deadline,
      createdBy: localStorage.getItem("userEmail"),
      url: contactPerson?.vendorDomain,
      remediationType: "forIssue",
      issueRemediationPlan: {
        description: formValues.description,
        issueName: formValues.name,
        remark: formValues.remark,
        url: formValues?.url?.split('\n').join(','),
        severity: selectedIssue.severity
      }
    }
    const url = `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_SEND_REMEDIATION_BY_USER}`;
    PostRequest(url, payload)
      .then((response) => {
        enqueueSnackbar(response.data.message, { variant: "success" });
        setLoading(false);
        handleCloseCreateRemediation();
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
        setLoading(false);
      });
  }

  const handleContactPersonChange = (e) => {
    setSelectedContactPerson(e.target.value);
  };
  return (
    <>
      {/* <Loader show={loading} /> */}
      <Card className="full-coverdesign margin-questionnaire">
        <div className="cover-table">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              top: "0px",
              left: "8px",
            }}
          >
            <FormControl
              className="select-entries"
              variant="outlined"
              size="small"
            >
              <Select
                value={paginationModel.pageSize}
                onChange={(e) => handlePaginationModelChange({ page: 0, pageSize: e.target.value })}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
              </Select>
            </FormControl>
            <span className="entries-per">entries per page</span>{" "}
          </div>
          <div className="issues-tab-table">
            <Box
              sx={{
                width: "100%",
                "& .MuiDataGrid-columnHeaders": {
                  minWidth: "100%",
                  overflowX: "hidden",
                },
                "& .MuiDataGrid-virtualScroller": {
                  minWidth: "100%",
                  overflowX: "auto",
                },
              }}
            >

              <DataGrid
                loading={loading}
                rows={filteredRows}
                columns={columnsNew}
                rowHeight={70}
                onPaginationModelChange={handlePaginationModelChange}
                paginationModel={paginationModel}
                components={{
                  NoRowsOverlay: () => (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        textAlign: "center",
                        fontSize: "18px",
                      }}
                    >
                      No data
                    </Box>
                  ),
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 5,
                    },
                  },
                  filter: {
                    filterModel: {
                      items: [],
                      quickFilterValues: [],
                    },
                  },
                }}
                autosizeOptions={{
                  columns: ["id", "firstName", "lastName"],
                  includeOutliers: true,
                  includeHeaders: false,
                }}
                pageSizeOptions={[5, 10, 25]}
                slots={{
                  toolbar: GridToolbar,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                disableRowSelectionOnClick
                disableColumnFilter
                disableColumnSelector
                disableDensitySelector
                disableColumnMenu
                paginationMode="client"
                disableSelectionOnClick
                autoHeight
                className="datagrid-table click-hover-datagrid"
                sx={{
                  "& .MuiDataGrid-columnHeaders": {
                    minWidth: "100%", // Column headers take up full width
                  },
                  "& .MuiDataGrid-virtualScroller": {
                    minWidth: "100%", // Rows take up full width
                  },
                }}
                onRowClick={handleRowClick}
              />
            </Box>
          </div>
        </div>
      </Card>
      {openSidebar &&
        <IssueSideBar
          issueData={selectedIssue}
          handleClose={() => setOpenSidebar(false)}
          open={openSidebar}
        />
      }
      <Modal
        open={openCreateRemediation}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="scroll-design">
          <div className="popup-remiadation">
            <div className="pop-flex-remiadation">
              <h3>Create Remediation</h3>
              <img src={CrossImg} style={{ cursor: 'pointer' }} alt="icon" onClick={handleCloseCreateRemediation} />
            </div>
            <form onSubmit={sendRemediation} fullWidth sx={{ mb: 3 }}>
              <div className="popup-form-remidaition">
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    {!selectedContactPerson ? "Select Person" : ""}
                  </InputLabel>
                  <TextField
                    labelId="demo-simple-select-label"
                    select
                    defaultValue=""
                    value={selectedContactPerson || ""}
                    onChange={handleContactPersonChange}
                    sx={{ mb: 3 }}
                  >
                    {contactPersonList && contactPersonList.length >= 0 && contactPersonList.map((item) => (
                      <MenuItem key={item._id} value={JSON.stringify(item)}>
                        {item.firstName} {item.lastName} {`(${item.email})`}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
                <TextField
                  name="name"
                  label="Issue Name"
                  fullWidth
                  value={formValues.name}
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
                <TextField
                  label="Description"
                  name="Description"
                  multiline
                  fullWidth
                  rows={3}
                  // maxRows={4}
                  sx={{ mb: 3 }}
                  value={formValues.description}
                  onChange={handleChange}
                />
                <TextField
                  label="Affected URLs"
                  name="URL"
                  fullWidth
                  multiline
                  rows={3}
                  sx={{ mb: 3 }}
                  value={formValues.url}
                  onChange={handleChange}
                />
                <TextField
                  label="Remediation Steps"
                  name="Remediation"
                  multiline
                  fullWidth
                  rows={3}
                  // maxRows={4}
                  sx={{ mb: 3 }}
                  value={formValues.remediation}
                  onChange={handleChange}
                />
                <TextField
                  name="deadline"
                  InputLabelProps={{ shrink: true, required: true }}
                  type="date"
                  label="Deadline"
                  className="deadline-input"
                  onChange={handleChange}
                  value={formValues.deadline}
                  inputProps={{
                    min: getCurrentDate(new Date()),
                    onKeyDown: (e) => e.preventDefault(),
                    style: {
                      WebkitAppearance: 'none', // Remove on Chrome/Safari/Edge
                      MozAppearance: 'textfield', // Remove on Firefox
                      appearance: 'none', // General appearance removal
                      position: 'relative',
                    },
                  }}
                  sx={{ color: "white", mb: 3 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {/* {/ Custom image for opening the date picker /} */}
                        <img
                          src={CalendarTodayIcon}
                          alt="calendar icon"
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            document.getElementsByName('deadline')[0].showPicker();
                          }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  label="Remark"
                  name="remark"
                  multiline
                  fullWidth
                  rows={3}
                  // maxRows={4}
                  value={formValues.remark}
                  onChange={handleChange}
                  sx={{ mb: 3 }}
                />
                <div className="button-alin-remaidation">
                  <Button onClick={handleCloseCreateRemediation} variant="contained" className="cancel-popup-btns">
                    Cancel
                  </Button>
                  <Button type="submit" variant="contained" className="cancel-popup-btns save-direct">
                    Save
                  </Button>
                </div>

              </div>
            </form>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default Issues;
