import { Card, CardHeader,Box,CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
const ReportChart = ({ title, chartData, loading }) => {
  const theme = useSelector((state) => state.theme.theme);
  const [themeColor, setThemeColor] = useState(theme); // State to store the theme color

  useEffect(() => {
    // Retrieve theme properties from local storage
    const savedTheme = localStorage.getItem("theme");
    // Use the saved theme color or set a default color
    setThemeColor(savedTheme ? savedTheme.toLowerCase() : "light");
  }, []);

  // Extract vendor names and scores from chartData
  const categories = chartData?.map((item) => item.vendor);
  const scores = chartData?.map((item) => item.score);

  const colors = [
    '#FF0000', // Ends with the next color in the sequence
    '#FFA500',
    '#FFD700',
    '#008000'
  ];

const options = {
  series: [
    {
      name: "Score",
      data: scores,
    },
  ],
  chart: {
    type: "bar",
    height: 350,
    events: {
      click: function (chart, w, e) {
        // console.log(chart, w, e)
      },
    },
    toolbar: {
      show: false,
    },
  },
  colors: colors,
  plotOptions: {
    bar: {
      columnWidth: "50px", // Adjust the columnWidth as needed
      borderRadius: 10, // Apply the radius uniformly to all corners
      borderRadiusApplication: 'end', // Apply the radius to the top-right and bottom-right
      borderRadiusWhenStacked: 'all', // Apply radius to the stacked bars
      distributed: true,
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    position: 'right',
    horizontalAlign: 'top',
    fontSize: "14px",
    offsetX: -70,
    markers: {
      width: 11,
      height: 12,
      shape: 'square',
      radius: 0,
      offsetY:0,
      offsetX: -5,
      fontSize: "14px",
    }
  },
  tooltip: {
    enabled: true,
    theme: theme,
  },
  xaxis: {
    categories: categories,
    axisBorder: {
      show: false, // Hide the x-axis border line
    },
    axisTicks: {
      show: false, // Hide the ticks on the x-axis
    },
    grid: {
      show: false, // Hide the x-axis grid lines
    },
    labels: {
      color: '#fff',
      style: {
        colors: '#fff',
        fontSize: "16px",
        fontFamily: 'Mona-Sans, sans-serif',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: theme === "dark" ? "#fff" : "#000",
        fontSize: "16px",
        fontFamily: 'Mona-Sans, sans-serif',
      },
    },
    axisBorder: {
      show: false, // Hide the y-axis border line
    },
    axisTicks: {
      show: false, // Hide the ticks on the y-axis
    },
    grid: {
      show: false, // Hide the y-axis grid lines
      borderColor: 'transparent', // Ensure the grid line color is transparent
      strokeDashArray: 0, // Set to 0 to remove any dashed lines
    },
  },
  fill: {
    type: 'gradient',
    gradient: {
      shade: 'dark',
      type: 'vertical',
      shadeIntensity: 0.5,
      gradientToColors: [
        '#FF0000', // Ends with the next color in the sequence
        '#FFA500',
        '#FFD700',
        '#008000'
      ],
      inverseColors: false,
      opacityFrom: 1,
      opacityTo: 1,
      stops: [0, 100]
    },
  },
};


  return (
    <>
      <Card>
        <div className="card-headerpart">
          <CardHeader
            title={title}
            sx={{ marginLeft: "0px", fontSize: "1.0rem" }}
          />
        </div>
        {loading ? <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "200px" }}>
          <CircularProgress />
        </Box> :
          <ReactApexChart
            options={options}
            series={options.series}
            type="bar"
            width="83%"
            height="260"
          />
        }
      </Card>
    </>
  );
};

export default ReportChart;
