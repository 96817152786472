import { useTheme } from "@mui/material/styles";
import { Box, Button, Card, CircularProgress, Modal } from "@mui/material";
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import Markdown from "marked-react";
import { useNavigate } from "react-router-dom";
import ArrowDown from "../assets/arrow-down-report.svg";
import GenAi from "../assets/gen-ai.svg";
import Loader from "../Loader";
import PostRequest from "../components/apiConnections/postRequest";
import GetRequest from "../components/apiConnections/getRequest";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "#120A3A",
  border: "0px solid #000",
  boxShadow: 24,
  borderRadius: "10px",
  p: 0,
  height: "80vh",
  overflowY: "auto",
};

export default function DocumentAnalysis({ domain }) {
  const userEmail = window.localStorage.getItem("userEmail");
  const [showLoader, setShowLoader] = useState(false)
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [modelOpen, setModelOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    navigate(`/dashboard/genesis-ai`, { state: { domain: domain } });
  };

  const handleDownloadReport = async () => {
    setShowLoader(true);
    // setDisableDownloadPDFButton(true);
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_VENDOR_GENERATED_PDF}?url=${domain}&createdBy=${userEmail}`,
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/pdf",
            },
          }
        )
          .then((res) => {
            console.log(res,"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
            const pdfData = new Blob([res.data], { type: "application/pdf" });
            const pdfUrl = URL.createObjectURL(pdfData);
            window.open(pdfUrl, "_blank");
            // setDisableDownloadPDFButton(false);
            setShowLoader(false);
          })
          .catch((err) => {
            // no data
            enqueueSnackbar("Error while generating PDF", { variant: "error" });
            // setDisableDownloadPDFButton(false);
            setShowLoader(false);
            return false;
          });
      }
    } catch (error) {
      // no data
      // setDisableDownloadPDFButton(false);
      enqueueSnackbar(error.message || "Error while generating PDF", { variant: "error" });
      setShowLoader(false);
      return false;
    }
  }
  return (
    <>
      <Loader show={showLoader} />
      <div className="flex-ai-animation">
        <Button variant="contained" className="download-report-button" onClick={handleDownloadReport}>
          <img src={ArrowDown} alt="img" /> Generate Report
        </Button>
        <div className="animation-ai-round" onClick={handleClick}>
          {" "}
          <img src={GenAi} alt="img" /> Genesis AI
        </div>
      </div>
      {/* <Button
        variant="contained"
        className="genesis-ai-btn"
        disabled={loading}
        onClick={handleClick}
      >
        Genesis AI
        {loading && <CircularProgress size={12} sx={{ marginLeft: '15px' }} />}
      </Button> */}
      {/* <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => {
          getVendorOverallDocAnalysis();
          handleClose();
        }}>Overall AI Assessment</MenuItem>
        <MenuItem onClick={() => {
          getSOC2ReportAnalysis();
          handleClose();
        }}>SOC2 Report AI Assessment</MenuItem>
      </Menu> */}

      <Modal
        open={modelOpen}
        onClose={() => setModelOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={
          theme === "Dark"
            ? "dark-attack-modal modal-popup"
            : "light-attack-modal modal-popup"
        }
      >
        <Box sx={style} className="black-bg openai-response">
          {data?.overallRisk ? (
            <Card
              sx={{ p: 2, m: 0, width: "100%" }}
              className="attackpart bg-cover-colors"
            >
              <Markdown value={data.overallRisk} />
            </Card>
          ) : null}

          {data?.soc2Risk ? (
            <Card
              sx={{ p: 2, m: 0, marginTop: "0rem", width: "100%" }}
              className="attackpart bg-cover-colors"
            >
              <Markdown value={data.soc2Risk} />
            </Card>
          ) : null}
        </Box>
      </Modal>
    </>
  );
}
