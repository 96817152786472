import React, { useEffect, useState, useRef } from 'react';
import GaugeComponent from 'react-gauge-component';
import PostRequest from "../../../components/apiConnections/postRequest";
import { useSnackbar } from 'notistack';
import UpIcon from '../../../assets/up-icon.svg';
import DownIcon from '../../../assets/down-arrow.svg';
import { Box, CircularProgress } from '@mui/material';
import colors from "../../../utils/colorcode";

function GaugeChart({ title, riskScore , riskTitle}) {
  const riskData = Number(riskScore) / 100;
  const [showLoader, setshowLoader] = useState(false);
  const [Overallscore, setOverallscore] = useState(2);
  const { enqueueSnackbar } = useSnackbar();
  const userEmail = window.localStorage.getItem("userEmail");
  const [option, setOption] = useState(7);
  const [latestScanAt, setLatestScanAt] = useState('');
  const [avgPercentageChangeInRiskScore, setAvgPercentageChangeInRiskScore] = useState(0);
  
  const updateOverallscore = async () => {
    setshowLoader(true);
    const payload = {
      username: userEmail,
      selectedDays: option
    }
    const endpoint = process.env.REACT_APP_OVERALL_SCORE_DASHBOARD;
    try {
      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );
      if (refreshToken) {
        await PostRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${endpoint}`, payload
        )
          .then((resOverallscore) => {
            const response = resOverallscore.data.data;
            // console.log("response" , response )
            const score = Number(response?.overall_score);
            const scannedDate = getFormatedDate(response?.latestScanAt);
            riskTitle(score)
            setOverallscore(score);
            setLatestScanAt(scannedDate);
            setAvgPercentageChangeInRiskScore(response?.avgPercentageChangeInRiskScore);
            setshowLoader(false);

          })
          .catch((errOverallscore) => {
            enqueueSnackbar(errOverallscore.message, { variant: 'error' });
            setshowLoader(false);
          })
      }
      else {
        setshowLoader(false);
        window.location.href = "/";
      }
    }
    catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
      setshowLoader(false);
    }
  }
  
  useEffect(() => {
    updateOverallscore();
  }, [])

  useEffect(() => {
    if (Overallscore) {

      const displayValue = Overallscore;
      const displayMax = 100;

      const valueTextElement = document.querySelector('.value-text text');
      if (valueTextElement) {
        valueTextElement.innerHTML = '';

        const scoreTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        scoreTspan.textContent = displayValue;
        scoreTspan.setAttribute('style', 'font-size: 40px; font-weight: bold; fill: white;');

        const maxTspan = document.createElementNS('http://www.w3.org/2000/svg', 'tspan');
        maxTspan.textContent = `/${displayMax}`;
        maxTspan.setAttribute('style', 'font-size: 20px; fill: white; margin-left: 5px;');

        valueTextElement.appendChild(scoreTspan);
        valueTextElement.appendChild(maxTspan);
      }
    }
  }, [Overallscore])

  function getFormatedDate(formateDate) {
    const date = new Date(formateDate); // Convert the string to a Date object
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    return `${day} ${month}`; // Return the formatted date
  }
  return (
    <>
      {showLoader ?  (
        <Box sx={{ display: "flex", justifyContent: "center",minHeight: "190px", alignItems: "center", width: "100%" }}>
          <CircularProgress />
        </Box>
        ) :(
        <div className='gauge-semicircle gauge-container' style={{ width: '500px', height: '200px' }}>
          {avgPercentageChangeInRiskScore >= 0 ? 
          (<span className="number-up position-right-side-overall">
            <img src={UpIcon} alt="up icon" /> {avgPercentageChangeInRiskScore}%
          </span>) :
          (<span className="number-down position-right-side-overall">
            <img src={DownIcon} alt="down icon" /> {avgPercentageChangeInRiskScore}%
          </span>
          )}
          <GaugeComponent
            type="semicircle" // Using a semicircular gauge
            arc={{
              colorArray: [colors.RED, colors.ORANGE, colors.YELLOW, colors.GREEN,],
              padding: 0.06, // Adjust padding for smoother arc transitions
              subArcs: [
                { limit: 25 },  // 25% of the total gauge
                { limit: 50 },  // Next 25%
                { limit: 75 },  // Next 25%
                { limit: 100 }, // Last 25%
              ],
              arcWidth: 0.8, // Adjust arc thickness
            }}

            pointer={{ type: 'blob', animationDelay: 0, color: "#ffffff" }} // Similar to the circle in the image
            value={Overallscore} // Adjust value as needed
            label={{
              display: true,
              text: `${Overallscore}/100`,
              style: {
                fontSize: '25px',
                fontWeight: 'bold',
                fill: '#ffffff',
                fontFamily: 'Mona-Sans',
              },
            }}
          />
          <p className='last-scan' style={{ textAlign: 'center', color: '#fff', marginTop: '0px' }}>
            Last scanned {latestScanAt}
          </p>
        </div>
      )}
    </>
  );
}

export default GaugeChart;
