import React, { useEffect, useState } from "react";
import GetRequest from "../../components/apiConnections/getRequest";
import PostRequest from "../../components/apiConnections/postRequest";
import {
  Box,
  Grid,
  Container,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import NewHeader from "../../../src/layouts/dashboard/header/NewHeader";
import ProfileCyber from "../../assets/cyber-security.png";
import RelationshipImg from "../../assets/relationship-info.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ViewAnswerPage from "../ViewAnswerPage";
import ComplianceScoring from "./ComplianceScoringPage"
import ComplianceMappingPage from "./ComplianceMappingPage";
import PlaneSend from "../../assets/plane-send.svg";
import Inprogress from "../../assets/in-progress.svg";
import Subimetted from "../../assets/submitted.svg";
import ExtendedDeadline from "../../assets/ExtendedDeadline.svg";
import Completed from "../../assets/completed-icon.svg";
import Breadcrumb from "./Breadcrumb";
import BackArrow from '../../assets/back-arrow.svg';
import moment from "moment";
function VendorDetailAnswer() {
  const paramData = useParams();
  const location = useLocation();
  const [assessmentData, setAssessmentData] = useState(paramData?.id);
  const { data } = location.state;
  const { mainTabValue: mainTabValue} = location.state;
  const [parentValue, setParentValue] = useState(0); // State for parent tabs
  const [assessmentStatus, setAssessmentStatus] = useState(data.assessmentStatus);
  const [domain, setDomain] = useState(data.url);
  const [logoLink, setLogoLink] = useState();
  const navigate = useNavigate();
  const handleParentChange = (event, newValue) => {
    setParentValue(newValue);
  };

  const [categoryValue, setCategoryValue] = useState("") ; 
  const toggleCategoryValue = (index) => {
    setCategoryValue(index);
    setParentValue(0)
  };
  const statusOrder = ['Sent', 'In Progress', 'Submitted', 'Engagement', 'Extended Deadline', 'Completed'];
  const breadcrumbs = [
    { label: 'Ecosystem', path: '/dashboard/vendors' },
    { label: 'Security',
       path: `/dashboard/vendorDetails/${domain}`, mainTabValue: 2},
    { label: 'Compliance' },
  ];

  // console.log("logoLink", logoLink);
  useEffect(() => {

    if (data?.setId) {
      getQuestionairelogoLink(data.setId);
    }

  }, [data]);

  const getQuestionairelogoLink = async (setId) => {
    try {

      const refreshToken = await PostRequest(
        `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_REFRESH_TOKEN}`,
        {},
        {},
        "refreshToken"
      );

      if (refreshToken) {
        GetRequest(
          `${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_BASE_URL}${process.env.REACT_APP_GET_LOGO_LINK_BY_SET_ID}?id=${setId}`,
          {}
        )
          .then((data) => {
            setLogoLink(data.data?.data?.logo)
          })
          .catch((errApi) => {
            // enqueueSnackbar("Requested Url not found", { variant: "error" });

          })
      }
    } catch (error) {
      // no data
      // enqueueSnackbar("Requested Url not find", { variant: "error" });
      // setshowLoader(false);
      // setError(true);
      return false;
    }
  };

  return (
    <>
      <Helmet>
        <title>Genesis Platform</title>
      </Helmet>
      <Container maxWidth={false} className="max-w-left">
      {mainTabValue && <NewHeader titleName="Security Profile" />}
        <Grid container spacing={3} className="ipad-space">
          <Grid item xs={12} md={12} lg={12}>
           {mainTabValue ? <div className="security-breadcum">
              <div>
                <Breadcrumb crumbs={breadcrumbs}/>
              </div>
            </div> :
            <Typography variant="h4" sx={{ mb: 0 }}>
                   <span className='backlink category-score-text-heading' style={{marginTop:"5px", marginBottom:"0px",paddingLeft:"0" }}>
                       <img src={BackArrow} onClick={()=>navigate("/dashboard/assessment")}alt='icon' />
                        Questionnaire
                   </span>
             </Typography>
             }
          </Grid>

          <Grid item xs={12} md={12} lg={12}>
            <div className="cybersecurity-assessment-box">
              <p>
                <span className="dpcyber">
                  {logoLink ?
                    <img className="logo-image-vendor attack-surface-list-logo" src={`${process.env.REACT_APP_BACKEND_BASE_URL}${process.env.REACT_APP_QUESTIONNAIRE_LOGO}/${logoLink}`} alt='logo' />
                    :
                    <img src={ProfileCyber} alt="icon" />
                  }
                </span>
                {data?.set || "-"}
              </p>
              <span> Deadline: { isNaN(data?.deadline)
              ? data?.deadline
              : moment.unix(data?.deadline).format("DD/MM/YYYY") || "N/A" }</span>
            </div>

            <div className="three-steps-tabs viewdetail-answer">
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 0 ? 'activeprofile' : ''}`}>
                <div className="steps-image">
                  <img src={PlaneSend} alt="icon" />
                </div>
                <p>Sent </p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 1 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={Inprogress} alt="icon" />
                </div>
                <p>In Progress</p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 2 ? 'activeprofile' : ''}`}>
                <div className="steps-image">
                  <img src={Subimetted} alt="icon" />
                </div>
                <p>Submitted</p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 3 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={RelationshipImg} alt="icon" />
                </div>
                <p>Engagement</p>
              </div>
              <div className={`select-steps behind-line ${statusOrder.indexOf(assessmentStatus) >= 4 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={ExtendedDeadline} alt="icon" />
                </div>
                <p>Extended Deadline</p>
              </div>
              <div className={`select-steps ${statusOrder.indexOf(assessmentStatus) >= 5 ? 'activeprofile' : ''}`}>
                <div className="steps-image ">
                  <img src={Completed} alt="icon" />
                </div>
                <p>Completed</p>
              </div>
            </div>

            <Box className="tophangechange" sx={{ width: '100%' }}>
              {/* Parent Tabs */}
              <Tabs
                value={parentValue}
                onChange={handleParentChange}
                aria-label="Parent Tabs" className="tab-main-heading margin-mui-tabfix"
                sx={{ borderBottom: 1, borderColor: 'divider' }}
              >
                <Tab label="Answer" />
                {data.completed[0]?.submitted && <Tab label="Scoring" />}
                {data.completed[0]?.submitted && <Tab label="Mapping" /> }
              </Tabs>

              <Box sx={{ p: 0, m:0, }}>
                {/* Content for Parent Tab One with nested child tabs */}
                {parentValue === 0 && (
                  <div className="space-questionnaire">
                    {/* <div className="tabs-bg top-demo-space w-100-160"> */}
                    <ViewAnswerPage parentValue={1}  categoryValue={categoryValue}/>
                    {/* {navigate(`/dashboard/view-answer-page/${id}`, { state: { email, prevUrl: '/dashboard/assessment' } })} */}
                  </div>
                )}

                {/* Content for Parent Tab Two */}
                {parentValue === 1 && <Typography><ComplianceScoring setCategoryValue= {toggleCategoryValue}/> </Typography>}

                {/* Content for Parent Tab Three */}
                {parentValue === 2 && <Typography><ComplianceMappingPage domainName={domain} assessmentId={assessmentData} /></Typography>}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default VendorDetailAnswer;
